import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive';
import { fetchAPI } from '../../../utils';
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom';
import LoadingCircle from '../../../components/LoadingCircle';
import PackdrawGradient from '../../../icons/sites/packdraw_gradient.png'
import PackdrawText from '../../../icons/sites/packdraw_text.png'
import { Button } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import HistoryModal from '../../../components/Leaderboard/Packdraw/HistoryModal';

interface PackDrawWager {
    name: string;
    avatar: string;
    wagered: number;
    prize: number;
    placement: number;
}

const packdrawColor = "rgb(191, 128, 246)";

const textGradient = "linear-gradient(160deg, #fbbbee 0%, #bf80f6 25%, #826fff, 75%, #42cfff 100%)"

const LeaderboardRow = ({ wager }: { wager: PackDrawWager }) => {
  return (
    <Box component="tr" className={`${wager.placement === 1 ? "first" : wager.placement === 2 ? "second" : wager.placement === 3 ? "third" : ""}`}>
      <Box component="td" sx={{ textAlign: 'start', pl: '24px !important' }}>
        <Typography className={`placement`}>{wager.placement}</Typography>
      </Box>
      <Box component="td" sx={{ textAlign: 'start' }}>
        <Typography>{wager.name}</Typography>
      </Box>
      <Box component="td" sx={{ justifyContent: 'flex-end' }}>
        <Typography>${Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(wager.wagered)}</Typography>
      </Box>
      <Box component="td" sx={{ width: '120px', pr: '24px !important', textAlign: 'end' }}>
        <Typography>${Intl.NumberFormat("en-US").format(wager.prize)}</Typography>
      </Box>
    </Box>

  )
}

const GradientText = ({ children, sx }: { children: React.ReactNode, sx?: any }) => {
    return (
      <Typography
        component="span"
        sx={{
          color: packdrawColor,
          fontWeight: "600",
          background: textGradient,
          backgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textShadow: "0 0 .25rem rgba(191, 128, 246, .5)",
          ...sx
        }}
      >
        {children}
      </Typography>
    );
}

const Timer = ({ timeLeft }: { timeLeft: { days: number, hours: number, minutes: number } }) => {

    return (
      <GradientText sx={{ fontSize: '2.5em', display: 'flex', alignItems: 'center' }}>
        {timeLeft.minutes <= 0 && timeLeft.days <= 0 && timeLeft.hours <= 0  ? (
          "New Weekly Leaderboard will start soon."
        ) : (
          <ul>
            <li style={{ display: 'inline-block' }}>{timeLeft.days} Days</li>
            <li style={{ display: 'inline-block', margin: '0 .5rem' }}>&bull;</li>
            <li style={{ display: 'inline-block' }}>{timeLeft.hours} Hrs</li>
            <li style={{ display: 'inline-block', margin: '0 .5rem' }}>&bull;</li>
            <li style={{ display: 'inline-block' }}>{timeLeft.minutes} Mins</li>          
          </ul>
        )}
      </GradientText>
    );
}  

function getTimeLeft(date: string | number | Date) {

    const remainingTime = new Date(date).getTime() - Date.now()

    if(remainingTime <= 0) return {
        days: 0,
        hours: 0,
        minutes: 0
    }

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24))
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60))

    return {
        days,
        hours, 
        minutes
    };
  }


const getLeaderboardResetTime = () => {
    let now = new Date();
    
    let nextReset = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, 1, 0, 0, 0, 0));
  
    let nextResetTime = nextReset.getTime();
  
    if(nextResetTime <= now.getTime()){
        nextReset.setUTCMonth(nextReset.getUTCMonth() + 1)
        nextResetTime = nextReset.getTime();
    } 
  
    return nextResetTime;
}


const PackdrawLeaderboardPage = () => {
    const [leaderboard, setLeaderboard] = useState<Array<PackDrawWager>>([])
    const [loadingLeaderboard, setLoadingLeaderboard] = useState(true)

    const [weeklyResetTime, setWeeklyResetTime] = useState(0)
    const [weeklyResetTimeLeft, setWeeklyResetTimeLeft] = useState(0)

    const [previousLeaderboardModalOpen, setPreviousLeaderboardModalOpen] = useState(false)

    const isSmallDevice = useMediaQuery({ query: '(max-width: 800px)' })

    useEffect(() => {

        setWeeklyResetTime(getLeaderboardResetTime());

        async function getRoyaleCasesLeaderboard() {
            const data: any = await fetchAPI('/leaderboard/packdraw', 'GET').catch((e) => null);

            if (data) {
                setLeaderboard(data.leaderboard)
                setLoadingLeaderboard(false);
            }
        }

        getRoyaleCasesLeaderboard()

    }, [])

    useEffect(() => {
        if (weeklyResetTime > 0) {
            setWeeklyResetTimeLeft(weeklyResetTime - Date.now());

            const interval = setInterval(() => {
                setWeeklyResetTimeLeft(weeklyResetTime - Date.now())
            }, 10000);

            return () => {
                clearInterval(interval)
            }
        }
    }, [weeklyResetTime])

    return (
      <Box
        className="page"
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          marginBottom="5rem"
          width="100%"
        >
          <Box
            sx={{
              marginBottom: "2rem",
              marginTop: "2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "auto",
              height: "100px",
              columnGap: ".25rem",
            }}
          >
            <img src={PackdrawGradient} alt="Packdraw Logo" style={{ height: '72px', width: 'auto', display: isSmallDevice ? 'none' : 'inherit' }} />
            <img src={PackdrawText} alt="Packdraw Logo" style={{ height: '100px', width: 'auto' }} />

          </Box>
          <Box display="flex" flexDirection="column" rowGap=".25rem">
            <GradientText sx={{ fontSize: "3.5em", letterSpacing: "-1px" }}>
              $20,000
            </GradientText>
            <Typography sx={{ fontSize: "2.5em", letterSpacing: "-1px" }}>
              Monthly
            </Typography>
            <GradientText sx={{ fontSize: "2.5em", letterSpacing: "-1px" }}>
              Leaderboard
            </GradientText>
          </Box>
          <Box
            display="flex"
            marginTop="4rem"
            flexDirection="column"
            alignItems="center"
            rowGap="1rem"
          >
            <Typography
              sx={{ fontSize: "1.5em", color: "var(--text-primary)" }}
            >
              <b>
                Wager under code{" "}
                <Typography
                  component={Link}
                  to="/casino"
                  sx={{
                    color: packdrawColor,
                    letterSpacing: "-1px",
                    fontWeight: "600",
                    fontSize: "1.25em",
                    "&:hover": { color: packdrawColor + "aa" },
                  }}
                >
                  DDK{" "}
                </Typography>
              </b>
            </Typography>
            <Typography sx={{ color: "var(--text-secondary)" }}>
              Leaderboard resets monthly every 1st at 00:00 UTC
            </Typography>
          </Box>
          <Box marginTop="2rem">
            <Timer timeLeft={getTimeLeft(weeklyResetTime)} />
          </Box>


          <Box marginTop="2rem" sx={{ width: { xs: '100%', lg: '900px' }}}>
            {loadingLeaderboard ? (
              <Box sx={{ marginTop: "2rem", color: "var(--text-secondary)" }}>
                <LoadingCircle loadingText="Loading Leaderboard" />
              </Box>
            ) : leaderboard.length === 0 ? (
              <Box sx={{ marginTop: "2rem" }}>
                <Typography>
                  Start wagering now to place on the leaderboard
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ width: !isSmallDevice ? "900px" : "100%" }}
              >
                                <Box display="flex" width="100%">
                  <Button
                    onClick={() => setPreviousLeaderboardModalOpen(true)}
                    disableRipple
                    sx={{ marginLeft: "auto" }}
                    startIcon={<HistoryIcon />}
                  >
                    Previous Leaderboard
                  </Button>
                </Box>
                <Box sx={{ overflow: 'auto', width: '100%' }}> 
                <table className="leaderboard-table">
                  <thead>
                    <tr style={{ animationDelay: "0ms" }}>
                        <Box component="th" sx={{ color: packdrawColor, width: '120px', textAlign: 'start !important', pl: '24px !important'}}>#</Box>
                        <Box component="th" sx={{ color: packdrawColor, textAlign: 'start !important' }}>User</Box>
                        <Box component="th" sx={{ color: packdrawColor }}>Wagered</Box>
                        <Box component="th" sx={{ color: packdrawColor, width: '120px', textAlign: 'end !important', pr: '24px !important' }}>Prize</Box>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboard.map((wager, index) => (
                      <LeaderboardRow
                          key={`packdraw-leaderboard-row-${index}`}
                          wager={wager}
                      />
                    ))}
                  </tbody>
                </table>
                </Box>
                <Typography
                  sx={{
                    color: "var(--text-disabled)",
                    marginTop: ".25rem",
                    marginRight: "auto",
                    maxWidth: "100%",
                  }}
                >
                  * Data updates every ~30 minutes.
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <HistoryModal
          open={previousLeaderboardModalOpen}
          handleClose={() => setPreviousLeaderboardModalOpen(false)}
        />
      </Box>
    );
}

export default PackdrawLeaderboardPage