import React, { useState } from 'react'
import useSWR from 'swr'
import { fetcher } from '../../../utils'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import { LeaderboardSkeleton } from '../Stake/Leaderboard';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800px',
    maxWidth: '95%',
    bgcolor: 'var(--primary-bg)',
    border: '2px solid var(--primary-border)',
    boxShadow: 24,
    padding: '1rem',
    borderRadius: '8px'
  }

const COLOR = "#ddb43f";

const PreviousLeaderboardModal = () => {

    const [isOpen, setIsOpen] = useState(false)
    const handleClose = () =>  setIsOpen(false)
    const handleOpen = () => setIsOpen(true)

    const { data, isLoading } = useSWR<{name: string, wagered: number, prize: number, placement: number }[]>(isOpen ?  `/leaderboard/cases/previous` : "", fetcher)

  return (
    <>
        <Button disableRipple variant="contained" color="soft" sx={{ color: 'var(--text-secondary)', textTransform: 'capitalize' }} onClick={handleOpen}>
            Previous Leaderboard            
        </Button>
        <Modal
            open={isOpen}
            onClose={handleClose}

        >
            <Box display="flex" flexDirection="column" gap="1rem" position="relative" sx={{ ...style, maxHeight: '80%' }}>
                <IconButton sx={{ position: 'absolute', top: '0', right: '0' }}>
                    <Close onClick={handleClose} />
                </IconButton>
                <Typography variant="h5">Previous Leaderboard</Typography>

                <Box sx={{ overflowY: 'auto', px: '.25rem'}}>

                
                    {!data || data?.length === 0 ? (
                        <Typography sx={{ fontStyle: 'italic' }}>Please check back later.</Typography>
                    ) : (
                        <table className="leaderboard-table">
                            <thead>
                                <tr>
                                    <Box component="th" sx={{ color: COLOR }}>#</Box>
                                    <Box component="th" sx={{ color: COLOR }}>User</Box>
                                    <Box component="th" sx={{ color: COLOR }}>Wagered</Box>
                                    <Box component="th" sx={{ color: COLOR }}>Prize</Box>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    <>
                                        {new Array(10).fill(0).map((_, i) => (
                                            <LeaderboardSkeleton key={`leaderboard-skeleton-${i}`} />
                                        ))}
                                    </>
                                ) : !data ? (
                                    <td colSpan={4}>Something went wrong</td>
                                ) : (
                                    <>
                                        {data.map((wager, i) => (
                                            <Box component="tr" key={`leaderboard-row-${i}`}
                                            >
                                                <Box component="td" sx={{ textAlign: 'start', pl: '24px !important' }}>{i + 1}</Box>
                                                <Box component="td" sx={{ textAlign: 'start' }}>{wager.name}</Box>
                                                <Box component="td" sx={{ justifyContent: 'flex-end' }}>${Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(wager.wagered)}</Box>
                                                <Box component="td"  sx={{ width: '120px', pr: '24px !important', textAlign: 'end' }}>${Intl.NumberFormat("en-US").format(wager.prize)}</Box>
                                            </Box>
                                        ))}
                                    </>
                                )}
                            </tbody>
        
                        </table>
                    )}
                </Box>


            </Box>
        </Modal>
    </>
  )
}

export default PreviousLeaderboardModal