import React, { useEffect, useState } from 'react'
import Modal from '../../Modal'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { fetchAPI } from '../../../utils';
import LoadingCircle from '../../LoadingCircle';

type ModalProps = {
    open: boolean
    handleClose: () => void
}

type PackdrawLeaderboardWinner = {
    placement: number
    name: string
    wagered: number
    prize: number
}

const LeaderboardRow = ({ wager }: { wager: PackdrawLeaderboardWinner }) => {
    return (
      <Box component="tr" className={`${wager.placement === 1 ? "first" : wager.placement === 2 ? "second" : wager.placement === 3 ? "third" : ""}`}>
        <Box component="td" sx={{ textAlign: 'start', pl: '24px !important' }}>
          <Typography className={`placement`}>{wager.placement}</Typography>
        </Box>
        <Box component="td" sx={{ textAlign: 'start' }}>
          <Typography>{wager.name}</Typography>
        </Box>
        <Box component="td" sx={{ justifyContent: 'flex-end' }}>
          <Typography>${Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(wager.wagered)}</Typography>
        </Box>
        <Box component="td" sx={{ width: '120px', pr: '24px !important', textAlign: 'end' }}>
          <Typography>${Intl.NumberFormat("en-US").format(wager.prize)}</Typography>
        </Box>
      </Box>
  
    )
  }

const HistoryModal = ({ open, handleClose }: ModalProps) => {

    const [leaderboard, setLeaderboard] = useState<PackdrawLeaderboardWinner[]>([])

    const [loadingLeaderboard, setLoadingLeaderboard] = useState(true)

    useEffect(() => {

        async function getLeaderboard() {
         
            const data: any = await fetchAPI('/leaderboard/packdraw/previous').catch(e => null)

            if(data) {
                setLeaderboard(data.leaderboard);
                setLoadingLeaderboard(false);
            }
        } 
        

        if (open) {
            getLeaderboard()
        }

    }, [open])

  return (
      <Modal
          open={open}
          handleClose={handleClose}
          closeButton={true}
          width={"800px"}
      >
          <Box
              display="flex"
              flexDirection="column"
          >
              <Typography variant="h4" alignSelf="flex-start">
                  Previous Leaderboard
              </Typography>
              {loadingLeaderboard ? (
                  <LoadingCircle loadingText={"Loading previous Weekly Leaderboard"} />
              ) : leaderboard.length === 0 ? (
                  <Box
                      display="flex"
                      alignItems="center"
                  >
                      <Typography variant="h6" marginTop="1rem">
                          Check back next Week
                      </Typography>
                  </Box>
              ) : leaderboard.length > 0 && (
                  <table className="leaderboard-table">
                      <thead>
                          <tr>
                              <th></th>
                              <th></th>
                              <th className='packdraw'>Wagered</th>
                              <th className='prize packdraw'>Prize</th>
                          </tr>
                      </thead>
                      <tbody>
                          {leaderboard.map((winner, index) => (
                                <LeaderboardRow wager={winner} key={`packdraw-winner-${index}`} />
                          ))}
                      </tbody>
                  </table>
              )}

          </Box>
      </Modal>
  )
}

export default HistoryModal